export default {
  OFFLINE_MSG: "Please check your internet connection.",
  OFFLINE_MSG_DETAILS: "Your are currently offline and can't save any changes.",


  /* input filed error messages */
  PASSWORD_ERROR: "Password can not be empty",
  PASSWORD_LENGTH_ERROR: "Password must be at least 8 characters",
  PASSWORD_MATCH_ERROR: "Password mismatch",
  MOBILE_NUMBER_ERROR: "Mobile Number can not be empty",
  INVALID_MOBILE_NUMBER_ERROR: "Mobile number must be valid",
  EMAIL_ERROR: "Email can not be empty",
  EMAIL_MUST_BE_VALID: "Please Enter a valid Email",
  FILE_ERROR: "Please Selects File",
  IFSC_CODE: "Invalid IFSC Code",
  ACCOUNT_NUMBER: "Invalid Account Number",
  ACCOUNT_NUMBER_MISMATCH: "Account number mismatch",


//    contact details


}