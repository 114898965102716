export default {
    BASE_URL: "http://localhost:8000/api",
    // BASE_URL: "https://api.tutorsfactory.com/api",
    // BASE_URL: "http://tutorsfactory.com:8080/api",


    /* master api */
    BLOG_LIST: "/blog/list/",
    BLOG_DETAILS: "/blog/details/",


}

